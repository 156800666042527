export const m = {    
    message: '我是英文',  
    text1: 'Account to be recharged',
    text2: 'Verify',
    text3: 'Whisper ID : ',
    text4: 'What is Whisper ID >',
    text5: 'Recharge for another Whis ID',
    text6: 'Payment ways',
    text7: 'Choose Currency >',
    text8: 'FAQ',
    text9: 'About Us >',
    text10:'Customer Service Contact:',
    text11: 'Email：whisperfeedback@gmail.com',
    text12: 'RM502C, 5/F, HO KING COMM CTR, 2-16 FAYUEN ST, MONGKOK KOWLOON, HONG KONG',
    text13: `Whisper is a social app and provides users the best experience for group voice chat rooms and other entertainment activities. This website is for virtual coin recharge for Whisper app.`,
    text14: 'The virtual coin recharge price may be updated according to our policies, kindly be noted.',
    text15: 'English',
    text16: 'اللغة العربية',
    text20: 'Turkish',
    text17: 'Choose Currency',
    text18: 'Input Whisper ID', 
    text19: 'Confirm to purchase Luxury Pass? It will be activated from {passStartDate} to {passEndDate} after purchase',
    titleText: 'Chat & Party',   //副标题 

    loginplease: 'Verify ID to\n check >',
    paymentText: 'Payment amount',
    giftandday: '{m} offer {n} day',
    giftandday1: '{m} offer {n} days',
    usd: 'USD',
    pass: 'Luxury Pass',
    choosepaymethods: 'Choose payment method',
    czcoinsandoffers: 'Recharge coins and offers',
    coinsrecharge: 'Coins recharge',
    offer: 'Achievement offer',
    offerText: 'offer',
    coinsText: 'coins',
    buygiftPack: 'Purchase offer', //购买礼包
    offerbuyRecords: 'Offer Purchase Records',
    czspecialdayforzen: 'During recharge bonus days',
    rechargeTips: [
        'Tips: only when you recharge in the offer will your recharges be counted in that offer.', 
        'Offer purhchase records >',        
    ],
    //未登录购买礼包文案
    nobuygiftTips: [
        'Tips: only when you recharge in the offer will your recharges be counted in that offer.', 
        '<span style="color:#FF4D4F;">You need to verify your ID to purchase offer or check offer progress ></span>',        
    ],
    timeList: {
        1: '24 hours',
        2: '7 days',
        3: '30 days'
    },

    //成就礼包规则
    offerrule: 'Achievement offer rules',
    offertext1: 'Achievement offer is a recharge offer system. Complete the required recharge amount of the offer before the expired date, you can get the additional coins!',
    offertextList: {
        1: ['1. You need to purchase the offer then make it activated and start counting your recharge of the offer.'],
        2: [
            // '2. You need to complete the recharge amount required by the offer to get coins rewards, coins rewards will change according to your real recharge status.',
            // 'For example: you purchased 2000 USD offer that is valid for 24 hours, you can complete the tasks in that offer.',
            // 'If you choose to recharge 500 USD twice and 100 USD once to complete the 2000 USD recharge task, that means you‘ve recharged 930000 coins, rhen you can get 170000 coins rewards.',
            // `If you complete the 2000 USD recharge task by recharging 200 USD for 10 times, that means you've recharged 80000 coins, then you can get 300000 coins rewards.`
            `2. You need to complete the recharge amount required by the offer to get the additional coins, the additional coins amount will base on the recharges you have done.`,
            `For example: if you purchased a 2000 USD offer that is valid for 24 hours, you should complete 2000 USD recharge within 24 hours.`,
            `If you recharge 500 USD twice and 1000 USD once to complete the 2000 USD recharge, that means you've recharged 930K coins, then you can get 170K additional coins.`,
            `If you complete the 2000 USD recharge by recharging 200 USD 10 times, that means you've recharged 800K coins, then you can get 300K additional coins.`
        ],
        3: [
            // '3.Each achievement offer has 3 kinds of validity: 24 hous, 7 days, 30 days If you purchase 600 USD offer that is valid for 24 hours, you need to recharge 600 USD in 24 hours. After completing the recharge task, the coin rewards will be sent immediately!',
            // 'If you fail to complete the recharge task within 24 hours, you can only get the coins you recharged, no additional coins rewards.'        
            `3. There are 3 kinds of validities for the offers: 24 hours, 7 days, 30 days`,
            `If you purchase a 600 USD offer that is valid for 24 hours, you need to recharge 600 USD in 24 hours. After completing the recharge task, the additional coins will be sent immediately!`,
            `If you fail to complete 600 USD within 24 hours, you can only get the coins you have recharged, no additional coins.`
        ],
        4: ['4. After you purchased the offer, there are recharge options in the offer. Please select the recharge options in the offer to recharge. Tips: only in this way will your recharges be counted for the offer.'],
        5: [`5. You can purchase several offers at same time, and the recharges of different offers are counted seperately, you need to enter each offer page to recharge for that offer.`],
    },
    authfor: 'For any query, please contact Whisper Customer Service Team.',

    offerneed: 'Offer recharge needed: ',   //任务要求充值：
    openoffer: 'Purchase offer', //开启礼包
    expiredDate: 'Expired date: ', //礼包有效期：
    curreny: 'USD {n}', //USD %s
    buyrecords: 'Offer purhchase records >', //礼包购买记录>
    go: 'Go',  //去完成
    going: 'Ongoing',   //正在进行
    complted: 'Completed', //已完成
    expired: 'Expired', //已过期
    frozen: 'Frozen',   //冻结
    compltedandsent: '<p>Completed</p> rewards have been sent', //已完成，奖励已发放
    nomore: 'No more',

    giftrechargerecords: 'Offer Recharge Records',  //当前礼包充值记录
    openrechargebonusdays: 'Recharge Bonus Days Started',   //充值特惠日开启!
    rechargedesctext1: `'Time-limited activity "recharge bonus days" started, you can get extra coins when you recharge coins, come and join the activity. (Achievement offer purchase and the countdown will be frozen during recharge bonus days)'`,
    rechargedesctext:  `Time-limited activity "recharge bonus days" started, you can get extra coins when you recharge coins, come and join the activity.`, //充值特惠日限定开启！充值金币将会得到的额外金币奖励，快来参加吧 (成就礼包将会被冻结进度，无法同时参与充值优惠日活动)
    compltedtask: `Total coins you can get if you complete the offer recharges (including the coins you've recharged): `, //礼包任务充值完成后共可获得金币（包含充值本金）：
    ok: 'OK',
    nodata: 'No data',
    confirmbuyDesc: 'Confirm to purchase {n} USD offer?',
    youneedtopay: 'You need to pay {n} USD. Tips: Only when you complete offer recharge task before the offer expires can you get offer rewards',
    confirm: 'confirm',
    confirm1: 'Confirm',
    cancel: 'cancel',
    cancel1: 'Cancel',
    loading: 'Loading... ',
    loosingtips: '',

    confirmRechargeoffer: 'Confirm to purchase {m} USD offer {n} days?',
    confirmRechargecoins: 'Confirm to purchase {n} coins?',
    youneedtopayoffertips: 'You need to pay {n}. Tips: Only when you complete offer recharge task before the offer expires can you get offer rewards',
    youneedtopaycoinstips:'You need to pay {n}',

    day: 'day',
    days: 'days', 
    hrs: 'hour',
    min: 'min',
    sec: 'sec',
    seconds: 's',

    coins: 'coins',
    name: 'Offer Name',
    fillIncode: 'Fill in the verification code',
    fillcodeTips: 'Verification code has been sent to the system notification of Whisper',
    sendAgain: 'Send Again',
    loginin: 'Log in',
    s: 's',
    buyCoins: 'Purchase coins',
    buyGift: 'Purchase price',

    nologinTips: 'Please input ID first',
    idnotValid:　'ID not valid',

    noBuytips: `It's a recharge bonus day, \n the offer is currently frozen. \n If you failed to purchase it, \n please refresh the page`,  //当前为充值特惠日，礼包为冻结期，购买失败，请重新刷新当前页面
    nousecouponTips:  "Unable to use coupon, \n it's currently Recharge Offer Day",   //无法使用优惠券，当前是充值特惠日
    rechargemore900Tips: "Unable to use coupon, \n the coupon is unavailable \n when recharging over 900 USD", //无法使用优惠券，充值超过900美金将无法使用优惠券
    hasbuyPasscardTips: "Failed to purchase, \n you've already purchased \n luxury pass in this period", //购买失败，您在此期间已经购买了通行证
    youneedto30LevelTips: "Sorry, you need to reach to User Level 30 first", //对不起，你需要先达到用户等级30
    refrsehTips: 'This offer has expired, \n please refresh this page \n and re-purchase it', //礼包已过期请刷新页面重试(这个是后台的提示，在吐司文档里面)
    pleaseCheckid: 'Click to verify your Whisper ID',
    expiredTips: 'Verification expired, please enter the verification code again', //验证过期，请重新填写验证码

    identityVerify: 'Please verify your ID to check offer progress or purchase offer >',
    buygiftPacktips: 'You need to verify your ID to purchase offer or check offer progress >',
    verifiStaus: {
        0: 'Not verified',
        1: 'Verified',
    },

    giftpackongoing: 'Achievement offer is ongoing',
    joingiftpageCalc: 'It will be counted in offer progress',
    giftpackinprogress: '{n} offer is ongoing',
    gotorecharge: 'The offer is ongoing, go to complete the recharge',

    hasbuyGiftpack: 'Tips: you have already purchased an offer',
    hasbuyGiftpackdesc: 'Each account can have one offer at the same time, if you want to purchase another offer, ',
    hasbuyGiftpackdescred: '<span style="color: #FF4D4F;">the offer you have purchased will be canceled automatically, and the offer recharge progress will be reset to 0</span>',
    changegiftPack: 'Change offer',
    canceled: 'Canceled',

    delayinOffertips: 'There may be a delay in offer purchase, please refresh it later',//延迟到账提示
    giftpackexpiredTips: 'The offer has expired, please refresh the current page and try again',
    openText: 'Show more recharge options',
    closeText: 'Show less recharge options',

    useCoupon: 'Use coupon',

    commonproblems: [
        {
            title: '1.Where to check my coins after recharge?',
            subheading: 'After recharge, you can open Whisper APP, and check your coins in "Me" - "Recharge Coins" page'
        },
        {
            title: `2. What if I didn't receive coins after recharge done?`,
            subheading: 'You can contact WhatsApp: +201112642082, and provide information: 1 recharge amount. 2 recharge date and time 3 screen shot of recharge bill. We shall verify the order and send you coins after that.'
        },
        {
            title: '3. What if I recharged for wrong ID?',
            subheading: `Sorry, you can't refund after any recharge, but you can contact the owner of the ID that you recharged for, and negotiate with him to pay you the amount.`
        }
    ]

}  

