//时间戳转时间格式
export function timeConvert (timestamp, num) {//num:0 YYYY-MM-DD  num:1  YYYY-MM-DD hh:mm:ss // timestamp:时间戳
    timestamp = timestamp + '';
    timestamp = timestamp.length == 10 ? timestamp * 1000 : timestamp;
    var date = new Date(timestamp);
    var y = date.getFullYear();
    var m = date.getMonth() + 1;
    m = m < 10 ? ('0' + m) : m;
    var d = date.getDate();
    d = d < 10 ? ('0' + d) : d;
    var h = date.getHours();
    h = h < 10 ? ('0' + h) : h;
    var minute = date.getMinutes();
    var second = date.getSeconds();
    minute = minute < 10 ? ('0' + minute) : minute;
    second = second < 10 ? ('0' + second) : second;
    if (num == 0) {
        return y + '-' + m + '-' + d;
    } else {
        return y + '-' + m + '-' + d + ' ' + h + ':' + minute + ':' + second;
    }
}

export default({
     /**
     * item   每一条数据值 value
     * ele    每一条数据的 key
     * fixNum 保留小数点后几位
    */
    unitChange(item, ele, fixNum) {
        let numO = {1: 10, 2: 100, 3: 1000}
        let _num = numO[fixNum]
        if (typeof item[ele] === 'number' && item[ele] >= 1000000) {
            let a = item[ele] / 1000000
            item[ele] = Math.floor(a * _num) / _num + 'M'
        } else if (typeof item[ele] === 'number' && item[ele] >= 1000 && item[ele] < 1000000) {
            let a = item[ele] / 1000
            item[ele] = Math.floor(a * _num) / _num + 'K'
        } else {
            item[ele] = item[ele]
        }
        return item[ele]
    },
    //获取两个时间戳之间相差的时间
    calculateTimeDifference(timestamp1, timestamp2) {
        var difference = Math.abs(timestamp2 - timestamp1); // 计算时间戳差值的绝对值
      
        var days = Math.floor(difference / (24 * 60 * 60 * 1000)); // 计算天数
        difference -= days * 24 * 60 * 60 * 1000; // 去除已计算的天数的毫秒数
      
        var hours = Math.floor(difference / (60 * 60 * 1000)); // 计算小时数
        difference -= hours * 60 * 60 * 1000; // 去除已计算的小时数的毫秒数
      
        var minutes = Math.floor(difference / (60 * 1000)); // 计算分钟数
        difference -= minutes * 60 * 1000; // 去除已计算的分钟数的毫秒数
      
        var seconds = Math.floor(difference / 1000); // 计算秒数
        return {
            days: days,
            hours: hours < 10 ? '0' + hours : hours,
            minutes: minutes < 10 ? '0' + minutes : minutes,
            seconds: seconds < 10 ? '0' + seconds : seconds
        };
    },
    
    //根据lang重置vue-i18n 中 this.$i18n.locale 的值
    resetLang(lang, _this){
        // console.log(lang, _this, '...........resetLang')
        let obj = {
            1: {
                langType: 'zh-EN'
            },
            2: {
                langType: 'zh-ER'
            },
            3: {
                langType: 'zh-TR'
            }
        }
        _this.$i18n.locale = obj[lang].langType
    },
    //判断是否是一个对象
    isObject(value) {
        return typeof value === 'object' && value !== null;
    },

    //验证token是否过期（是否校验账号） 1:已校验（未过期）  0:未校验（已过期）
    checkTokenauth(_userInfo){
        let {expire_time, token} = _userInfo 
        let nowTime = ((new Date().getTime()) / 1000).toFixed(0)
        // let ifExpired = expire_time && token && (+nowTime < (expire_time - 5 * 60))
        // // console.log(_userInfo, '..................._userInfo')
        // // console.log((+nowTime > expire_time) && token, '...................nowTime')
        // console.log(ifExpired, '...........用户登录信息是否过期')

        if(expire_time !== undefined && expire_time && token && (+nowTime < (expire_time - 5 * 60))) {
            return 1
        } else {
            return 0
        }
    },
    //校验一个对象是否为空
    isEmptyObject(obj) {
        for (var key in obj) {
          if (obj.hasOwnProperty(key)) {
            return false;
          }
        }
        return true;
    }
})